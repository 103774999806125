import styled from "styled-components";
import { Color, Font, breakpoints } from "@/shared/Constants";
import { cardMixin } from "../mixin";

export const ParentGrid = styled.div`
  grid-gap: 20px;
  margin: auto;
  width: max-content;
  grid-template-rows: inherit;
  display: grid;
  height: auto;
  overflow: hidden;
  grid-template-columns: repeat(4, 314px);
  grid-template-rows: repeat(2, 153px);
  > div {
    height: max-content;
  }
  .div1 {
    grid-area: 1 / 1 / 3 / 2;
  }
  .div2 {
    grid-area: 1 / 2 / 3 / 3;
  }
  .div3 {
    grid-area: 1 / 3 / 3 / 4;
  }
  .div4 {
    grid-area: 1 / 4 / 3 / 5;
  }
  ${breakpoints.tablet} {
    grid-template-columns: repeat(3, 314px);
    > div {
      &:nth-last-child(-n + 2) {
        height: 138px;
        overflow: hidden;
        article > div {
          padding-top: 0 !important;
          flex-direction: row !important;
          height: 100%;
          .mt {
            margin-top: 15px !important;
            width: 100%;
            margin-right: 20px;
            h2.title {
              font-size: 16px;
              line-height: 17px;
            }
            .volanta {
              font-size: 16px;
              line-height: 17px;
            }
          }
        }
        //Estilos skeleton
        &.div3.normal-skeleton,
        &.div4.normal-skeleton {
          height: 138px;
          overflow: hidden;
          padding-top: 0 !important;
          flex-direction: row-reverse !important;
          .mt {
            margin-top: 15px !important;
            width: 100%;
            margin-right: 20px;
          }
        }
      }
    }
    .div1 {
      grid-area: 1 / 1 / 3 / 2;
    }
    .div2 {
      grid-area: 1 / 2 / 3 / 3;
    }
    .div3 {
      grid-area: 1 / 3 / 2 / 4;
    }
    .div4 {
      grid-area: 2 / 3 / 3 / 4;
    }
  }
  ${breakpoints.phone} {
    max-height: max-content;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
`;
export const Card = styled.div`
  ${cardMixin};
  padding-bottom: 0;
  &:before {
    right: -10px !important;
  }
`;
