import Separator from "@/shared/Separator";
import { ParentGrid, Card } from "./style";
import { GridStyleColor } from "@/widgets/Container/Templates/stylesGridGeneric.js";
import { useContainerWidget } from "hooks";
import Skeleton from "./Skeleton";

const Container1PisoA = (props) => {
  const { link, sectionClass = "", title, linkImage } = props;

  const { indexNews, loading, titlesExtras, marfeelTitle } = useContainerWidget(
    { arrayLength: 4, containerName: "container1PisoA", ...props }
  );
  
  return (
    props.content?.length > 0 && (
      <>
        {title && (
          <Separator
            title={title}
            link={link}
            sectionClass={sectionClass}
            extras={titlesExtras}
            linkImage={linkImage}
          />
        )}
        {loading && !props.isPreview ? (
          <Skeleton />
        ) : (
          <>
            <GridStyleColor id={marfeelTitle}>
              <ParentGrid
                data-mrf-recirculation={`${marfeelTitle}`}
                className={`container1PisoA module-grid ${sectionClass}`}
              >
                {indexNews.map((item, index) => {
                  return (
                    <Card className={`div${index + 1}`} key={`container${index}`}>
                      {props.renderContainerWidgetCallback(
                        item + 1,
                        props.positionProperties?.[item]?.templateNews ??
                          "OnexOne",
                        { containerName: "contenedor_1_piso_tipo_a" }
                      )}
                    </Card>
                  );
                })}
              </ParentGrid>
            </GridStyleColor>
          </>
        )}
      </>
    )
  );
};

export default Container1PisoA;
