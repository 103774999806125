import { ParentGrid } from './style'
import { GenericNormalSkeleton } from '@/shared/Skeleton'

const Skeleton = () => {
  return (
    <ParentGrid className={`container1PisoA module-grid`}>
      {[...Array(4)].map((_, index) => (
        <GenericNormalSkeleton classType={`div${index + 1}`} key={index} />
      ))}
    </ParentGrid>
  )
}

export default Skeleton